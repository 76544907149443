@mixin centerPos($restProperties...) {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) join($restProperties, (), space);
}

@keyframes rotationKeyframes {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.wrapper {
  padding-left: 30px;
  padding-right: 30px;

  &_loading {
    opacity: 0.7;
    pointer-events: none;
  }
}

.scrapperWrapper {
  .title {
    margin-bottom: 20px;
    font-family: "Roboto", sans-serif;
    font-size: 25px;
  }

}

.spinner {
  position: absolute;
  width: 68px;
  height: 68px;
  border: 5px solid #0c7be2;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  animation: rotationKeyframes 1s linear infinite;
  @include centerPos();
}