.react-datepicker-wrapper {
  width: auto !important;
  

  input {
    padding: 4px 8px;
    border-radius: 5px;
    width: 200px;
    margin-right: 15px;
    border: 1px solid black;
   
  }
}