.button {
  padding: 8px 50px;
  width: auto;
  margin-right: 15px;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 15px;

  &:active {
    opacity: 0.7;
  }
}

.primary {
  background-color: #0c7be2;
}

.secondary {
  background-color: grey;
}