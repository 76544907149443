.wrapper {
  display: flex;
  flex-direction: column;
  width: 300px;
  font-size: 15px;
  margin-top: 30px;

  .text {
    margin-bottom: 10px;
  }

  .input {
    padding: 3px 8px;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 300px;
    border: 1px solid black;
  }

  .error {
    color: red;
    margin-bottom: 10px;
  }

  .button {
    width: 150px;
  }
}