.wrapper {
  display: flex;
  margin-bottom: 15px;

  .token {
    padding: 3px 8px;
    border-radius: 5px;
    margin-right: 15px;
    width: 200px;
    border: 1px solid black;
  }

  .url {
    padding: 3px 8px;
    border-radius: 5px;
    width: 200px;
    border: 1px solid black;
    margin-right: 15px;
  }

  .status {
    font-family: "Roboto", sans-serif;
    align-items: center;
    display: flex;

    .icon {
      width: 20px;
      height: 20px;
    }
  }
}