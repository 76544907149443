.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 70px 0;

  .columns {
    display: flex;

    .image {
      width: 420px;
      height: 440px;
      background: url('../../../../styles/img/social-media-bg.png') no-repeat center / cover;
      margin-right: 40px;
    }

    .services {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 70px;

      &__item {

        .icon {
          display: flex;
          margin-bottom: 30px;
          width: 45px;
          height: 45px;
        }

        .title {
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 25px;
        }

        .list {
          list-style-type: disc;

          &__item {
            line-height: 200%;
            font-size: 15px;

          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .wrapper {
    .columns {
      justify-content: center;

      .image {
        display: none;
      }

      .services {
        grid-template-columns: 1fr;
        max-width: 80%;
      }
    }
  }
}